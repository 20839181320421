.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 4vh;
  flex-shrink: 0;
  background-color: #333333;
  width: 100%;

  // @media (min-width: 800px) {  }
  @media (min-width: 1200px) {
    margin-top: 2vh;
  }
}
