.leftpane {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  background-color: #ebebec;
  padding-bottom: 4vh;
  width: 25vw;
  letter-spacing: 0.15em;
  overflow: hidden;
  .button-text {
    text-overflow: ellipsis;
  }
  .title-section {
    ul {
      padding-inline-start: 26px;
      color: #3e3e3f;
    }
  }

  .mobile-icon-list{
    justify-content: center !important;
  }

  @media (min-width: 1200px) {
    height: 55vh;
    margin-top: 3vh;

    .title-section {
      ul {
        line-height: 2.6vh;
      }
    }
  }

  @media (min-width: 950px) {
    .title-section {
      ul {
        margin-left: 16px;
        color: #3e3e3f;
      }
    }
  }
}
