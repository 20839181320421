:root {
  --font-profile: calc(5px + 1vw);

  @media (min-width: 400px) {
    --font-profile: calc(5px + 1.5vw);
  }

  @media (min-width: 950px) {
    --font-profile: calc(5px + 0.8vw);
  }

  @media (min-width: 1200px) {
    margin: 0 10vw;
    --font-profile: calc(9px + 0.3vw);
  }

  li {
    text-overflow: ellipsis;
    width: 100%;
  }
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: var(--font-profile);

  .mid-panes {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    align-items: flex-start;
    padding: 0;
  }

  .MuiSvgIcon-root,
  .MuiSvgIcon-fontSizeMedium,
  .MuiListItemIcon-root {
    min-width: 20px !important;
    width: 2.5vw !important;
    height: calc(var(--font-profile) * 2) !important;

    path,
    g path {
      fill: #171717;
    }
  }

  .MuiTypography-root {
    letter-spacing: 0.15em !important;
    font-size: var(--font-profile);
  }
}

MuiListItemIcon-root {
  height: 2vw;
}

// Animations
.animation-wobbler {
  -webkit-animation: wobble 1s 1;
  animation: wobble 1s 1;
}

@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes wobble {
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
