.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  margin: 0 10px;
  padding: 10px 0;
  height: 5vh;

  background-color: #333333;
  width: 100%;

  @media (min-width: 950px) {
    height: 8%;
  }

  @media (min-width: 1200px) {
    padding-top: 5vh;
  }

  // USE RESPONSIVE TYPOGRAPHY https://ryanfeigenbaum.com/easy-responsive-typography/
  &__name {
    color: white;
    font-size: calc(4px + 1vw);
    letter-spacing: 1.2vw;
    font-weight: 450;
    padding-left: 16px;
  }

  .open-work{
    animation: pulsate 2.5s infinite alternate;
    color: white;
    font-size: calc(4px + 1vw);
    line-height: 100%;
    margin-right: 4vw;
  }

}

@keyframes pulsate {
  100% {
    /* Larger blur radius */
    text-shadow:
      0 0 4px #fff,
      0 0 11px #fff,
      0 0 19px #fff,
      0 0 40px #0fa,
      0 0 80px #0fa,
      0 0 90px #0fa,
      0 0 100px #0fa,
      0 0 150px #0fa;
  }
  0% {
    /* Smaller blur radius */
    text-shadow:
      0 0 2px #fff,
      0 0 4px #fff,
      0 0 6px #fff,
      0 0 10px #0fa,
      0 0 45px #0fa,
      0 0 55px #0fa,
      0 0 70px #0fa,
      0 0 80px #0fa;
  }
}