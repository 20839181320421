.bottompane {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 10px;
  height: 14vh;
  background-color: #e1e4e4;
  width: 100%;

  // @media (min-width: 800px) {  }
  @media (min-width: 1200px) {
    margin: 0 10vw;
  }
}
