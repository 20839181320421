.rightpane {
  display: flex;
  flex: 1;
  flex-direction: column;
  letter-spacing: 0.15em;

  @media (min-width: 950px) {
    margin-left: 2.5vw;
  }

  @media (min-width: 1200px) {
    margin-top: 3vh;
    height: 62vh;
  }

  @media (max-width: 449px) {
    .text-section {
      padding-left: 16px !important;
    }
  }

  .text-section {
    padding-left: 16px;
    padding: 1em;
    &-w-list {
      padding-left: 16px;
      padding-top: 1em;
    }
  }
}
