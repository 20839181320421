.sectiontitle {
  .MuiDivider-root{
    background-color: #3E3E3F;
    width: 13vw;
    height: .1vh !important;
    margin-left: 8px;

  }
  

  &__name {
    color: #3E3E3F;
    font-size: calc(4px + 1.35vw);
    letter-spacing: .25vw;
    font-weight: 450;
    padding-left:  8px;
    padding-bottom: 0.1vh;
  }

  @media (min-width: 950px) {
    &__name {
      padding-left: 16px;
    }

    .MuiDivider-root{
      margin-left: 1rem;
    }
    
  }
}


